<template>
  <v-container
    :class="{
      'title-item-dark': $vuetify.theme.dark,
    }"
    fluid
  >
    
    <v-row class="mt-4">
      <v-col sm="12" md="3" class="mt-2">
        <v-label> Selecione uma empresa: </v-label>
      </v-col>
      <v-col sm="12" md="8">
        <v-autocomplete
          dense
          outlined
          label="Empresas  cadastradas"
          :items="empresas"
          item-text="pessoa.nome"
          item-value="id"
          :readonly="readonly"
          :loading="carregando"
          :disabled="carregando"
          :error-messages="errorMessages"
          autocomplete="false"
          hide-details="auto"
          @input="onChange"
          v-model="empresaId"
        />
      </v-col>
    </v-row>
    <v-divider class="mt-2"></v-divider>
    <!-- <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <ButtonAdicionar
            :temPermissao="
              $store.getters['usuario/temPermissao'](
                'inserir.area_configuracao'
              )
            "
            :to="`/empresa/${empresaId}/area_configuracaoAdmin/adicionar`"
          />
        </v-col>
      </v-row>
    </v-card-actions> -->

    <v-row class="mt-4">
      <v-col>
        <v-data-table
          disable-filtering
          disable-sort
          :server-items-length="totalRegistros"
          :headers="cabecalho"
          :items="areas"
          :options.sync="paginacao"
          :footer-props="{
            'items-per-page-options': [10, 20, 50],
          }"
          @update:options="carregarAreasEmpresas"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <table-btn-editar
              :temPermissao="
                $store.getters['usuario/temPermissao'](
                  'editar.area_configuracao'
                )
              "
              :href="`/empresa/${empresaId}/area_configuracaoAdmin/${item.id}/editar`"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import areaConfiguracao from "@/api/areaconfiguracao";
import empresas from "@/api/empresas";

export default {
  components: {
 //   ButtonAdicionar: () => import("@/components/button/ButtonAdicionar"),
  },

  data() {
    return {
      empresaId: 0,
      readonly: false,
      carregando: false,
     
      totalRegistros: 0,
      cabecalho: [
        { text: "#", value: "id" },
        { text: "Área", value: "area.nome" },
        { text: "Adquirente", value: "adquirenteName" },
        { text: "Cobrar na entrada", value: "entrada" },
        { text: "Ações", value: "actions", align: "right" },
      ],
      areas: [],
      empresas: [],
      paginacao: {},
      errorMessages: [],
    };
  },

  mounted() {
    this.buscarEmpresas();
  },

  methods: {
    async buscarEmpresas() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);

        const resposta = await empresas.listar({ por_pagina: 100 });
        this.empresas = resposta.data.data;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async carregarAreasEmpresas() {
      try {
        if (this.empresaId != 0) {
          this.$store.commit(`layout/SET_LOADING`, true);

          const resposta = await areaConfiguracao.listar({
            pagina: this.paginacao.page,
            por_pagina: this.paginacao.itemsPerPage,
            empresa: this.empresaId,
          });

          this.areas = resposta.data.data;
          this.areas.forEach((item) => {
            if (item.cobrar_entrada) {
              item.entrada = "Sim";
            } else {
              item.entrada = "Não";
            }
            item.adquirenteName = this.adquirenteToString(item.adquirente);
          });

          this.totalRegistros = resposta.data.meta.total;
        }
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
        console.log(e);
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    adquirenteToString(id) {
      switch (id) {
        case 1:
          return "STONE";
        case 2:
          return "SKYTEF";
        case 4:
          return "SAFRA";
        default:
          return "";
      }
    },

    onChange() {
      this.carregarAreasEmpresas();
    },
  },
};
</script>
